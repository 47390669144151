export function getContrastYIQ(hexcolor: string) {
  // Remove the hash if it's there
  hexcolor = hexcolor.replace("#", "");

  // Convert hex to RGB first
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Then calculate the luminance/brightness of the color
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black for bright colors, white for dark colors
  return yiq >= 128 ? "black" : "white";
}
